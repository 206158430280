.logo-menu {
    width: 65px;
    height: 65px;
    margin-right: 15px;
}

.navbar-light .navbar-nav .nav-link.active {
    color: var(--main-color);
    text-decoration: underline;
    text-underline-offset: 10px;
}

.navbar-light .navbar-nav .nav-link {
    /* font-size: 1.1em; */
    font-weight: bold;
    border-bottom: none;
}

.navbar-light .navbar-nav .nav-link:hover {
    text-decoration: underline;
    text-underline-offset: 10px;
}

.navbar-brand {
    border-bottom: none;
}

.bg-light {
    background-color: #fff !important;
    color: black;

    -webkit-box-shadow: 0px 2px 6px #0000001a;
    -moz-box-shadow: 0px 2px 6px #0000001a;
    box-shadow: 0px 2px 6px #0000001a;
}

.fixed-top {
    width: 100vw;
}