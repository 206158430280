.cookie-banner {
    position: fixed;
    bottom: 0;
    height: auto !important;
    min-height: 4em;
    width: 100%;
    /* background-color: var(--main-color-transparent); */
    /* background-color: #393939f0; */
    text-align: center;
    padding-top: 0;
    padding-bottom: 0;
    /* color: white; */
    /* cursor: var(--cursor-light); */
}

/* .light-cursor,
.cookie-banner .cookie-banner-message,
.cookie-banner span,
.cookie-banner div,
.cookie-banner > * {
    cursor: var(--cursor-light);
} */

.cookie-banner-message {
    font-size: 1em;
    margin-bottom: 0.5em;

    margin-top: 0.5em;
}

.btn-cookie {
    margin-top: 0em;
    margin-bottom: 0em;
}

@media (min-width: 768px) {
    
    .btn-cookie {
        margin-left: 1em;
    }

    .cookie-banner .container {
        margin-top: 0.25em;
    }
}

.btn-cookie-proposal {
    background-color: var(--main-color);
    /* font-weight: bold; */
    color: white;
    border-color: #ffffffc2;
}

.btn-cookie-container {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
}

.separator {
    width: 100%;
    height: 1px;
    background-color: #eaeaea;
    margin-top: 1.5em;
    margin-bottom: 1.5em;
    display: block;
}

.tier-name {
    font-weight: bold;
    color: grey;
}